export enum PermissionEnum {
  MANAGE_ROLE_PERMISSIONS = 'MANAGE_ROLE_PERMISSIONS',
  VIEW_USERS = 'VIEW_USERS',
  EDIT_USERS = 'EDIT_USERS',
  ADD_USER_PERMISSIONS = 'ADD_USER_PERMISSIONS',
  DELETE_USER_PERMISSIONS = 'DELETE_USER_PERMISSIONS',
  VIEW_RESERVATIONS = 'VIEW_RESERVATIONS',
  DELETE_RESERVATIONS = "DELETE_RESERVATIONS",
  VIEW_SKILLS_REGISTRY = "VIEW_SKILLS_REGISTRY",
  EDIT_SKILLS_REGISTRY = 'EDIT_SKILLS_REGISTRY',
  ADD_SKILLS_REGISTRY = 'ADD_SKILLS_REGISTRY',
  DELETE_SKILLS_REGISTRY = 'DELETE_SKILLS_REGISTRY',
  VIEW_MAPPING_SKILLS = 'VIEW_MAPPING_SKILLS',
  VIEW_ORDER_ACTIVATIONS = 'VIEW_ORDER_ACTIVATIONS',
  EDIT_ORDER_ACTIVATIONS = 'EDIT_ORDER_ACTIVATIONS',
  ADD_ORDER_ACTIVATIONS = 'ADD_ORDER_ACTIVATIONS',
  DELETE_ORDER_ACTIVATIONS = 'DELETE_ORDER_ACTIVATIONS',
  VIEW_TIMESHEETS = 'VIEW_TIMESHEETS',
  APPROVE_TIMESHEETS = 'APPROVE_TIMESHEETS',
  REJECT_TIMESHEETS = 'REJECT_TIMESHEETS',
  EXPORT_SUMMARY_REPORTS_TIMESHEETS = 'EXPORT_SUMMARY_REPORTS_TIMESHEETS',
  EXPORT_GIOTTO_TIMESHEETS = 'EXPORT_GIOTTO_TIMESHEETS',
  VIEW_ABSENCES = 'VIEW_ABSENCES',
  APPROVE_ABSENCES = 'APPROVE_ABSENCES',
  REJECT_ABSENCES = 'REJECT_ABSENCES',
  APPROVE_ABSENCES_HR ='APPROVE_ABSENCES_HR',
  REJECT_ABSENCES_HR = 'REJECT_ABSENCES_HR',
  DOWNLOAD_ABSENCES_DOC = 'DOWNLOAD_ABSENCES_DOC', // TODO: Lato admin
  VIEW_ORDERS = 'VIEW_ORDERS', // TODO: Unused
  VIEW_OVERTIMES = 'VIEW_OVERTIMES',
  APPROVE_OVERTIMES = 'APPROVE_OVERTIMES',
  REJECT_OVERTIMES ='REJECT_OVERTIMES',
  APPROVE_OVERTIMES_HR = 'APPROVE_OVERTIMES_HR',
  REJECT_OVERTIMES_HR ='REJECT_OVERTIMES_HR',
  VALIDATE_ABSENCES_DOCUMENTS = 'VALIDATE_ABSENCES_DOCUMENTS',
  REJECT_ABSENCES_DOCUMENTS = 'REJECT_ABSENCES_DOCUMENTS',
  VALIDATE_OVERTIMES_DOCUMENTS = 'VALIDATE_OVERTIMES_DOCUMENTS', // TODO: Lato admin
  REJECT_OVERTIMES_DOCUMENTS = 'REJECT_OVERTIMES_DOCUMENTS', // TODO: Lato admin
  MANAGE_FUNDED_TIMESHEETS = 'MANAGE_FUNDED_TIMESHEETS',
  MANAGE_MY_RESERVATIONS = 'MANAGE_MY_RESERVATIONS',
  MANAGE_MY_TIMESHEETS = 'MANAGE_MY_TIMESHEETS',
  MANAGE_MY_SKILLS = 'MANAGE_MY_SKILLS',
  MANAGE_MY_ABSENCES = 'MANAGE_MY_ABSENCES',
  MANAGE_MY_OVERTIMES = 'MANAGE_MY_OVERTIMES'
}
